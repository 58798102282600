import React from 'react';
import {useState} from "react";
import {PartnersPanel} from "./Panels/PartnersPanel";
import {ExportPanel} from "./Panels/ExportPanel";
import {ButtonsPanel} from "./Panels/ButtonsPanel";
import axios from 'axios';
import {SchwackePanel} from "./Panels/SchwackePanel";
import {StagePanel} from "./Panels/StagePanel";
import pjson from "../package.json"
import {AusgabePanel} from "./Panels/AusgabePanel";
import {OverviewPanel} from "./Panels/OverviewPanel";
import {HerstellerPanel} from "./Panels/HerstellerPanel";
import {KundePanel} from "./Panels/KundePanel";

const URI = `${process.env.REACT_APP_URL}`;

const DO_LOG_ERROR = false;

function useOutputValue(defaultValue = '') {
    const [value, setValue] = useState(defaultValue);

    return {
        setValue: v => setValue(v),
        value: () => value
    }
}

function useInputValue(defaultValue = '') {
    const [value, setValue] = useState(defaultValue);

    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        value: () => value
    }
}

function useCheckboxValue(defaultValue = []) {
    const [items, setItems] = useState(defaultValue);

    const addValue = (toAdd) => {
        setItems([...items, toAdd])
    }

    const removeAll = () => {
        setItems([])
    }

    const removeValue = (toRemove) => {
        const array = [...items]
        const index = array.indexOf(toRemove)
        if (index !== -1) {
            array.splice(index, 1);
            setItems(array)
        }
    }

    const handleOnChange = (event) => {
        const checked = event.target.checked;

        if (checked) {
            addValue(event.target.value)
        } else {
            removeValue(event.target.value)
        }
    }

    return {
        bind: {
            onChange: event => handleOnChange(event)
        },
        items: () => items,
        removeAll: () => removeAll()
    }
}

function execute(url, finished) {
    console.log("requesting: " + url);
    axios.post(url)
        .then(resp =>
            finished.setValue(timeStamp() + ": " + url + " - " + resp.data)
        )
        .catch(error => {
            if (DO_LOG_ERROR) {
                console.log(error);
            }
        })

}

function createRequestWithMandant(toExport, partners, mandant, kundeId) {
    const del = '/';
    let request = URI;

    switch (toExport) {
        case 'conditions':
        case 'policy':
            request = request + mandant + del + toExport + del + partners;
            break;
        case 'lpl':
            request = request + mandant + del + partners + del + toExport + del + kundeId;
            break;
        default:
            request = null;
    }

    return request;
}

function createRequest(toExport, codes, hersteller) {
    const del = '/';
    let request = URI;

    switch (toExport) {
        case 'configs':
        case 'historic':
            if (codes.length > 0) {
                request = request + toExport + 'byid' + del + codes;
            } else if (hersteller.length > 0) {
                request = request + toExport + del + 'hersteller' + del + hersteller;
            } else {
                request = request + toExport;
            }
            break;
        case 'reifen':
            request = request + toExport;
            break;
        default:
            request = null;
    }

    return request;
}

function buildVersion() {
    const buildVersion = `${process.env.REACT_APP_BUILD_VERSION}`;
    if (buildVersion) {
        return buildVersion
    } else {
        return 'undefined'
    }
}

function timeStamp() {
    const date = Date.now();
    return new Intl.DateTimeFormat('de-DE',
        {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        })
        .format(date);
}

function clickElement(elem) {
    const el = document.getElementById(`checkbox-${elem}`)
    el.click()
}

export const ExportForm = () => {
    const classicItems = useCheckboxValue([]);
    const blueItems = useCheckboxValue([]);
    const koopItems = useCheckboxValue([]);
    const exportItems = useCheckboxValue([]);
    const schwackeTextField = useInputValue();
    const kundeIdTextField = useInputValue();
    const herstellerTextField = useInputValue();
    const started = useOutputValue();
    const finished = useOutputValue();

    const handleSubmit = event => {
        event.preventDefault();
        const codes = schwackeTextField.value();
        const hersteller = herstellerTextField.value();
        const kundeId = kundeIdTextField.value();

        const classicPartners = classicItems.items();
        const bluePartners = blueItems.items();
        const koopPartners = koopItems.items();
        const toExport = exportItems.items();

        toExport.forEach(e => {
            clickElement(e)
        })
        classicPartners.forEach(e => {
            clickElement(e)
        })
        bluePartners.forEach(e => {
            clickElement(e)
        })
        koopPartners.forEach(e => {
            clickElement(e)
        })
        exportItems.removeAll()
        classicItems.removeAll()
        blueItems.removeAll()
        koopItems.removeAll()

        toExport.forEach(item => {
            if (classicPartners.length > 0) {
                const classicrequest = createRequestWithMandant(item, classicPartners, 'classic', kundeId);
                if (classicrequest) {
                    started.setValue(timeStamp() + ": started export " + item);
                    finished.setValue("");
                    execute(classicrequest, finished);
                } else {
                    alert("False Request built!");
                }
            }
            if (bluePartners.length > 0) {
                const bluerequest = createRequestWithMandant(item, bluePartners, 'blue', kundeId);
                if (bluerequest) {
                    started.setValue(timeStamp() + ": started export " + item);
                    finished.setValue("");
                    execute(bluerequest, finished);
                } else {
                    alert("False Request built!");
                }
            }
            if (koopPartners.length > 0) {
                const kooprequest = createRequestWithMandant(item, koopPartners, 'koop', kundeId);
                if (kooprequest) {
                    started.setValue(timeStamp() + ": started export " + item);
                    finished.setValue("");
                    execute(kooprequest, finished);
                } else {
                    alert("False Request built!");
                }
            }
            if (classicPartners.length === 0
                && bluePartners.length === 0
                && koopPartners.length === 0) {
                const noMandantRequest = createRequest(item, codes, hersteller);
                if (noMandantRequest) {
                    started.setValue(timeStamp() + ": started export " + item);
                    finished.setValue("");
                    execute(noMandantRequest, finished);
                } else {
                    alert("False Request built!");
                }
            }
        })
    }

    return (
        <div className="MainPanel">
            <header className="MainPanel-header">
                <h2>
                    Speed Export
                </h2>
            </header>
            <div style={{textAlign: "right", paddingRight: "10px", marginTop: "-30px"}}>
                {pjson.name} {pjson.version}:{buildVersion()}
            </div>

            <StagePanel/>

            <form onSubmit={handleSubmit}>
                <PartnersPanel classicPartners={classicItems} bluePartners={blueItems} koopPartners={koopItems}/>
                <ExportPanel exports={exportItems}/>
                <KundePanel kundeId={kundeIdTextField}/>
                <SchwackePanel codes={schwackeTextField}/>
                <HerstellerPanel hersteller={herstellerTextField}/>
                <ButtonsPanel/>
                <AusgabePanel started={started} finished={finished}/>
            </form>

            <OverviewPanel/>
        </div>
    )
}
