import './StagePanel.css';
import {Panel} from '../tags/Panel.js'
import React from "react";
import {StagePanelButton} from "./StagePanelButton";
import {StagePanelProxyButton} from "./StagePanelProxyButton";


const proxy = `${process.env.REACT_APP_PROXY}`;

export const StagePanel = () => {
    if (proxy === 'true') {
        return (
            <Panel title={`${process.env.REACT_APP_STAGE}`}>
                <div className="stageTable">
                    <StagePanelProxyButton name="SpeecExport Version" url="ping"/>
                    <StagePanelProxyButton name="SpeecCalc Version" url="speedcalc/ping"/>
                </div>
            </Panel>
        );
    }
    else {
        return (
            <Panel title={`${process.env.REACT_APP_STAGE}`}>
                <div className="stageTable">
                    <StagePanelButton name="SpeecExport Version" url="ping"/>
                    <StagePanelButton name="SpeecCalc Version" url="speedcalc/ping"/>
                </div>
            </Panel>
        );
    }
}