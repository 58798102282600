import React from "react";
import './AusgabePanel.css';

export const ERROR_APPENDIX = " - completed with ERROR.";

export const AusgabePanel = ({started, finished}) => {

    return (
        <div className="Ausgabe" >
            <p>{started.value()}</p>
            <p>{finished.value()}</p>
        </div>
    );

}
