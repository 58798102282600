import './SchwackePanel.css';
import {Panel} from "../tags/Panel";

export const SchwackePanel = (props) => {
    const codes = props.codes;
    const bind = codes.bind;

    return (
        <Panel title="Choose Schwacke Codes for CarConfigs/HistoricPrices Export (optional)">
            <div className="SchwackePanel">
                <input type="text" value={codes.value} {...bind} placeholder="Comma separated values"/>
            </div>
        </Panel>
    );
}