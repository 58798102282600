import './Button.css';

export const Button = (props) => {
    return (
        <div className="Button">
            <button onClick={props.onClick} type="submit">
                {props.name}
            </button>
        </div>
    );
}