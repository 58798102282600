import React from 'react';
import './App.css';
import {ExportForm} from './ExportForm';
import {LoginForm} from './LoginForm';
import useToken from './hooks/useToken';


export const App = () => {
    const { token, setToken } = useToken();

    if (!token) {
        return <LoginForm setToken={setToken}/>
    }

    return <ExportForm/>
}