import './ExportPanel.css';
import {Panel} from "../tags/Panel";
import {Checkbox} from "../tags/Checkbox";

export const ExportPanel = (props) => {
    const exports = props.exports;

    return (
        <Panel title="Choose Export" >
            <div className="ExportPanel">
                <Checkbox label="Car Configurations" value="configs" values={exports} />
                <Checkbox label="Calc Conditions" value="conditions" values={exports} />
                <Checkbox label="Tires" value="reifen" values={exports} />
                <Checkbox label="Policies" value="policy" values={exports} />
                <Checkbox label="Historic Prices" value="historic" values={exports} />
                <Checkbox label="LPL" value="lpl" values={exports} />
            </div>
        </Panel>
    );
}