import * as React from 'react';
import './OverviewPanel.css';
import {Panel} from "../tags/Panel";
import {Button} from "../tags/Button";
import axios from "axios";
import {useState} from "react";

const URI_BASE = `${process.env.REACT_APP_URL}`;
const URI = URI_BASE + 'overview';

let reqInstance = axios.create({
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ1bml0dGVzdCIsIm5iZiI6MTU5OTYzNjUzMywicm9sZXMiOlsiUk9MRV9DQUxDVUxBVEUiXSwiaXNzIjoiQUxEIEF1dG9tb3RpdmUiLCJleHAiOjE5MTQ5OTY1MzMsImlhdCI6MTU5OTYzNjUzMywianRpIjoiNGMzMGQxOTQtY2NkOS00OTExLWIwYjYtMDUzMDc3NDg4YzVlIn0.DGiHlzyqykd4MZMFq9vn_FQLyXlJx_q_5eTaUMhAYb8kNZ3yxu9SumUOcBsvoWwMAog_k8oTiaxgk4NlrwryiQ',
    }
})

function removeItem(stringToken){
    let url = URI_BASE + stringToken;
    console.log('removed: token=' + stringToken);
    reqInstance.delete(url).then(r => console.log(r));
}

function useRadio(defaultValue = 'open') {
    const [value, setValue] = useState(defaultValue);

    const updateValue = (v) => {
        setValue(v)
    }

    return {
        updateValue: v => updateValue(v),
        value: () => value
    }
}

export const OverviewPanel = () => {
    const radios = useRadio();
    let data = "";


    function getOverview() {
        const uriWithCloud = URI + "/" + radios.value()
        let list = Array.prototype.slice.call(document.getElementsByName('remove')).filter(function(e){
            return e.checked;
        });
        list.forEach(value => {
            removeItem(value.id);
        })
        reqInstance.get(uriWithCloud)
            .then(resp => {
                document.getElementById('overviewPanel').innerHTML = resp.data;
            }
        );
    }

    return (
        <Panel title="Last runs">
            <div id="overviewPanel" className="Overview" dangerouslySetInnerHTML={{__html: data}}>
            </div>

            <div className="Buttons">
                <div className="RadioGroup">
                    <input name="cloud" type="radio" id="open" value="open"
                           onClick={() => radios.updateValue('open')}
                           checked={radios.value() === 'open'} />
                    <label htmlFor="open">open</label>
                    <input name="cloud" type="radio" id="trusted" value="trusted"
                           onClick={() => radios.updateValue('trusted')}
                           checked={radios.value() === 'trusted'} />
                    <label htmlFor="trusted">trusted</label>
                </div>
                <Button name="Refresh Overview" onClick={getOverview}/>
            </div>
        </Panel>
    );
}
