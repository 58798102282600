import './PartnersPanel.css';
import {Panel} from '../tags/Panel.js'
import {Label} from '../tags/Label.js'
import {BlueCheckbox, FlotteCheckbox, KoopCheckbox} from "../tags/Checkbox";

export const PartnersPanel = (props) => {
    const classicPartners = props.classicPartners;
    const bluePartners = props.bluePartners;
    const koopPartners = props.koopPartners;

    const flotteHeader = <Label text="Classic" />;
    const flotteBody =  <FlotteCheckbox partners={classicPartners} />;

    const blueHeader = <Label text="Blue" />;
    const blueBody =  <BlueCheckbox partners={bluePartners} />;

    const koopHeader = <Label text="Koop" />;
    const koopBody =  <KoopCheckbox partners={koopPartners} />;

    return (
        <Panel title="Choose Partners for Export">
            <div className="partnersTable">

                <PartnerColumn header={flotteHeader} body={flotteBody} />
                <PartnerColumn header={blueHeader} body={blueBody} />
                <PartnerColumn header={koopHeader} body={koopBody} />

            </div>
            <div style={{paddingLeft: '10px', fontStyle: 'italic'}}>
                <p>No partner needed for "Car Configurations", "Historic Prices" and "Tires" export</p>
                <p>Only one partner for "LPL" export</p>
            </div>
        </Panel>
    );
}

const PartnerColumn = (props) => {
    return (
        <div className="partnerColumn">
            <div className="partnerHeader">
                {props.header}
            </div>
            <div className="partnerBody">
                {props.body}
            </div>
        </div>
    );
}