import './KundePanel.css';
import {Panel} from "../tags/Panel";

export const KundePanel = (props) => {
    const kundeId = props.kundeId;
    const bind = kundeId.bind;

    return (
        <Panel title="Choose KundeId to export (LPL only)">
            <div className="KundePanel">
                <input type="text" value={kundeId.value} {...bind} placeholder="Enter kundeId"/>
            </div>
        </Panel>
    );
}