import './Checkbox.css';

export const Checkbox = (props) => {
    const bind = props.values.bind;

    return (
        <div className="Checkbox">
            <label>
                <input id={`checkbox-${props.value}`} type="checkbox" value={props.value} {...bind} />
                <div className="newCheckbox" />
                <span className="checkboxLabel">{props.label}</span>
            </label>
        </div>
    );
}

export const FlotteCheckbox = (props) => {
    const partners = props.partners;

    return (
        <div className="classic">
            <Checkbox label="flotte" value="classic" values={partners} />
            <Checkbox label="smo" value="smo" values={partners} />
            <Checkbox label="cpm" value="cpm" values={partners} />
            <Checkbox label="lucky" value="lucky" values={partners} />
            <Checkbox label="partnervertrieb" value="partnervertrieb" values={partners} />
        </div>
    );
}

export const BlueCheckbox = (props) => {
    const partners = props.partners;

    return (
        <div className="blue">
            <Checkbox label="ford" value="ford" values={partners} />
        </div>
    );
}

export const KoopCheckbox = (props) => {
    const partners = props.partners;

    return (
        <div className="koop">
            <Checkbox label="renault" value="renault" values={partners} />
            <Checkbox label="nissan" value="nissan" values={partners} />
            <Checkbox label="toyota" value="toyota" values={partners} />
            <Checkbox label="opel" value="opel" values={partners} />
            <Checkbox label="hyundai" value="hyundai" values={partners} />
            <Checkbox label="infiniti" value="infiniti" values={partners} />
            <Checkbox label="jaguar" value="jaguar" values={partners} />
            <Checkbox label="landrover" value="landrover" values={partners} />
            <Checkbox label="kia" value="kia" values={partners} />
            <Checkbox label="polestar" value="polestar" values={partners} />
            <Checkbox label="tesla" value="tesla" values={partners} />
            <Checkbox label="lynkco" value="lynkco" values={partners} />
            <Checkbox label="levc" value="levc" values={partners} />
            <Checkbox label="smart" value="smart" values={partners} />
        </div>
    );
}