import './Label.css';

export const Label = (props) => {
    return(
        <div className="Label">
            <span>
                {props.text}
            </span>
        </div>
    );
}