import './Panel.css';

export const Panel = (props) => {
    return (
        <div className="Panel" >
            <div className="Panel-header">
                <h3>
                    {props.title}
                </h3>
            </div>
            {props.children}
        </div>
    );
}
