import './HerstellerPanel.css';
import {Panel} from "../tags/Panel";

export const HerstellerPanel = (props) => {
    const hersteller = props.hersteller;
    const bind = hersteller.bind;

    return (
        <Panel title="Choose Hersteller for CarConfigs/HistoricPrices Export (optional)">
            <div className="HerstellerPanel">
                <input type="text" value={hersteller.value} {...bind} placeholder="Hersteller id"/>
            </div>
        </Panel>
    );
}