import './StagePanel.css';
import {Button} from "../tags/Button";
import useAxios from 'axios-hooks'

const URI = `${process.env.REACT_APP_URL}`;


export const StagePanelButton = (props) => {
    const url = props.url;
    const name = props.name;

    const [{data}, getVersion] = useAxios({
            url: URI + url
        },
        {
            manual: true
        });



    return (
            <div>
                <div className="Buttons">
                    <Button name={name} onClick={getVersion}/>
                </div>
                <div className="Label">
                    <span>
                       <pre>{JSON.stringify(data, null, 2)}</pre>
                    </span>
                </div>
            </div>
    );
}