import React from 'react';
import './LoginForm.css';
import {Panel} from './tags/Panel';
import {useState} from 'react';

const USERNAME = "export";
const PASSWORD = "export";

export const LoginForm = ({ setToken }) => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = USERNAME === username && PASSWORD === password
        if (!token) {
            alert('Wrong credentials!')
            return
        }
        setToken(token);
    }

    return (
        <div className="LoginPanelWrapper">
            <Panel title="Authentication">
                <div className="LoginPanel">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <input type="text" placeholder="Username" onChange={e => setUserName(e.target.value)}/>
                        </div>
                        <div>
                            <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
                        </div>
                        <div>
                            <button type="submit">Log In</button>
                        </div>
                    </form>
                </div>
            </Panel>
        </div>
    )
}
